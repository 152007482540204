@use '@angular/material' as mat;

@mixin mat-tabs-override() {
    .mat-mdc-tab-header-pagination {
        box-shadow: none !important;
    }

    .mat-mdc-tab-links {
        .mat-mdc-tab-link {
            padding: 0;
            min-width: 0 !important;
            opacity: 1;
            margin-right: 24px;

            &:last-child {
                margin-right: 0;
            }

            .mdc-tab__ripple {
                display: none;
            }
        }
    }
}

@mixin mat-tabs-override-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .mat-mdc {
        &-tab-link-container {
            flex-grow: initial;
        }

        &-tab-links {
            border-color: map-get($foreground, divider);
        }

        &-tab-link .mdc-tab__text-label {
            color: map-get($foreground, text);
        }

        &-tab-nav-bar {
            border-color: transparent !important;
        }
    }
}

@mixin mat-tabs-override-typography($config) {
    .mat-mdc-tab-links {
        .mat-mdc-tab-link {
            @include mat.typography-level($config, subheading-1);
        }
    }

    .dsh-top-tab-nav-bar {
        .mat-mdc-tab-links {
            .mat-mdc-tab-link {
                @include mat.typography-level($config, subheading-2);
            }
        }
    }
}
