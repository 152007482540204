.photoviewer-modal {
  position: absolute;
  z-index: 1090;
  width: 320px;
  height: 320px;
  overflow: visible !important;
  color: #333;
  cursor: default;
  background-color: rgba(255, 255, 255, 0.92);
  border: 1px solid rgba(0, 0, 0, 0.64);
  border-radius: 6px;
  outline: none;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.32);
}

.photoviewer-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.photoviewer-maximized {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
}
.photoviewer-maximized.photoviewer-modal {
  border-width: 0;
  border-radius: 0;
}
.photoviewer-maximized .photoviewer-header {
  border-radius: 0;
}
.photoviewer-maximized .photoviewer-resizable-handle {
  display: none;
}

.photoviewer-toolbar {
  font-size: 0;
}

.photoviewer-button {
  display: inline-block;
  min-width: 40px;
  height: 40px;
  box-sizing: border-box;
  margin: 0;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  background: none;
  border-width: 0;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  outline: none;
}
.photoviewer-button:hover {
  color: #111;
}
.photoviewer-button:focus {
  background-color: rgba(0, 0, 0, 0.08);
}
.photoviewer-button svg {
  display: inline-block;
  font-size: inherit;
  width: 1em;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.photoviewer-header {
  position: relative;
  z-index: 2;
  height: 30px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}
.photoviewer-header .photoviewer-toolbar {
  float: right;
}
[dir=rtl] .photoviewer-header .photoviewer-toolbar {
  float: left;
}
.photoviewer-header .photoviewer-button {
  height: 30px;
}
.photoviewer-header .photoviewer-button:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.photoviewer-header .photoviewer-button-close:hover {
  color: #fff;
  background-color: #ff4545;
}

.photoviewer-title {
  padding: 8px 10px;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  overflow: hidden;
}

.photoviewer-stage {
  position: absolute;
  top: 30px;
  right: 0;
  bottom: 40px;
  left: 0;
  z-index: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.64);
  border-bottom: 1px solid rgba(0, 0, 0, 0.64);
  background-color: #fff;
  overflow: hidden;
}
.photoviewer-stage.stage-ready {
  text-align: center;
  font-size: 0;
}
.photoviewer-stage.stage-ready::before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  overflow: hidden;
}

.photoviewer-image {
  position: absolute;
  display: inline-block;
  min-width: auto;
  max-width: none;
}
.photoviewer-image.image-ready {
  position: static;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.photoviewer-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  text-align: center;
}
.photoviewer-footer .photoviewer-toolbar {
  display: inline-block;
}

[dir=rtl] .photoviewer-button-prev,
[dir=rtl] .photoviewer-button-next {
  transform: rotate(180deg);
}

.photoviewer-resizable-handle {
  position: absolute;
  z-index: 10;
}

.photoviewer-resizable-handle-e {
  top: 0;
  right: -5px;
  bottom: 0;
  left: auto;
  width: 10px;
  cursor: e-resize;
}

.photoviewer-resizable-handle-s {
  top: auto;
  right: 0;
  bottom: -5px;
  left: 0;
  height: 10px;
  cursor: s-resize;
}

.photoviewer-resizable-handle-w {
  top: 0;
  right: auto;
  bottom: 0;
  left: -5px;
  width: 10px;
  cursor: w-resize;
}

.photoviewer-resizable-handle-n {
  top: -5px;
  right: 0;
  bottom: auto;
  left: 0;
  height: 10px;
  cursor: n-resize;
}

.photoviewer-resizable-handle-se {
  top: auto;
  right: -5px;
  bottom: -5px;
  left: auto;
  width: 10px;
  height: 10px;
  cursor: se-resize;
}

.photoviewer-resizable-handle-sw {
  top: auto;
  right: auto;
  bottom: -5px;
  left: -5px;
  width: 10px;
  height: 10px;
  cursor: sw-resize;
}

.photoviewer-resizable-handle-nw {
  top: -5px;
  right: auto;
  bottom: auto;
  left: -5px;
  width: 10px;
  height: 10px;
  cursor: nw-resize;
}

.photoviewer-resizable-handle-ne {
  top: -5px;
  right: -5px;
  bottom: auto;
  left: auto;
  width: 10px;
  height: 10px;
  cursor: ne-resize;
}

:-webkit-full-screen {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
}
:-webkit-full-screen .photoviewer-header,
:-webkit-full-screen .photoviewer-footer,
:-webkit-full-screen .photoviewer-resizable-handle {
  display: none;
}
:-webkit-full-screen .photoviewer-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: black;
}

:-moz-full-screen {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
}
:-moz-full-screen .photoviewer-header,
:-moz-full-screen .photoviewer-footer,
:-moz-full-screen .photoviewer-resizable-handle {
  display: none;
}
:-moz-full-screen .photoviewer-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: black;
}

:-ms-fullscreen {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
}
:-ms-fullscreen .photoviewer-header,
:-ms-fullscreen .photoviewer-footer,
:-ms-fullscreen .photoviewer-resizable-handle {
  display: none;
}
:-ms-fullscreen .photoviewer-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: black;
}

:full-screen {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
}
:full-screen .photoviewer-header,
:full-screen .photoviewer-footer,
:full-screen .photoviewer-resizable-handle {
  display: none;
}
:full-screen .photoviewer-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: black;
}

:fullscreen {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
}
:fullscreen .photoviewer-header,
:fullscreen .photoviewer-footer,
:fullscreen .photoviewer-resizable-handle {
  display: none;
}
:fullscreen .photoviewer-stage {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 0;
  background-color: black;
}

::backdrop {
  background-color: black;
}

::-ms-backdrop {
  background-color: black;
}

.is-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.is-grabbing {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.photoviewer-loader {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 40px;
  z-index: 2;
  text-align: center;
  color: #333;
}
.photoviewer-loader::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
  animation: photoviewerLoading 1s infinite linear;
}
.photoviewer-loader::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden;
}

@keyframes photoviewerLoading {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotateZ(360deg) translate3d(0, 0, 0);
  }
}
.mat-button-loading .mdc-button__label,
.mat-button-loading .mat-icon {
  visibility: hidden;
}
.mat-button-loading .mat-mdc-progress-spinner {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}

/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable selector-no-vendor-prefix */
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-menu-panel.mat-menu-panel.mat-menu-panel {
  border-radius: 4px;
}
.mat-menu-panel.mat-menu-panel.mat-menu-panel .mat-mdc-menu-content {
  padding: 10px;
}
.mat-menu-panel.mat-menu-panel.mat-menu-panel .mat-mdc-menu-item {
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  padding: 0 9.5px;
  margin-bottom: 10px;
}
.mat-menu-panel.mat-menu-panel.mat-menu-panel .mat-mdc-menu-item:last-child {
  margin-bottom: 0;
}
.mat-menu-panel.mat-menu-panel.mat-menu-panel .mat-mdc-menu-item .mat-mdc-icon {
  margin-right: 10px;
}

.mat-mdc-theme-loaded-marker {
  display: none;
}

.mat-mdc-dialog-surface {
  box-shadow: none !important;
  padding: 24px !important;
  border-radius: 8px !important;
}
.mat-mdc-dialog-surface > *:last-child:after {
  display: block;
  content: "";
  height: 1rem;
  margin-bottom: -1rem;
}

.mat-mdc-snack-bar-container {
  box-shadow: none !important;
}

.mat-mdc-form-field-infix {
  width: auto !important;
}

.mat-mdc-checkbox-layout {
  white-space: normal !important;
}

.mat-mdc-checkbox-inner-container.mat-mdc-checkbox-inner-container {
  margin-top: 4px;
}

.mat-mdc-drawer-container {
  background: none !important;
}

.mat-mdc-radio-button * {
  cursor: pointer;
}

.mdc-radio {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}

.mat-mdc-radio-label {
  white-space: normal !important;
}

.mat-mdc-tab-header-pagination {
  box-shadow: none !important;
}

.mat-mdc-tab-links .mat-mdc-tab-link {
  padding: 0;
  min-width: 0 !important;
  opacity: 1;
  margin-right: 24px;
}
.mat-mdc-tab-links .mat-mdc-tab-link:last-child {
  margin-right: 0;
}
.mat-mdc-tab-links .mat-mdc-tab-link .mdc-tab__ripple {
  display: none;
}

.mat-mdc-icon-button.mat-mdc-icon-button.mat-mdc-icon-button.mat-mdc-icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.mdc-icon-button__ripple.mdc-icon-button__ripple.mdc-icon-button__ripple.mdc-icon-button__ripple {
  display: none;
}

body {
  margin: 0;
  transition: background 200ms ease-in-out;
}

@media screen and (max-width: 959px) {
  .dsh-dialog-pane {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
  }
  .dsh-dialog-pane .mat-mdc-dialog-container,
  .dsh-dialog-pane .mat-mdc-dialog-surface {
    border-radius: 0 !important;
  }
}