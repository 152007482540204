@import '../../utils/shadow';

$selector: '.mat-menu-panel.mat-menu-panel.mat-menu-panel';
$height: 40px;
$text-padding: 9.5px;
$content-padding: 10px;
$gap: 10px;
$border-radius: 4px;
$icon-padding: 10px;

@mixin mat-menu-override() {
    #{$selector} {
        border-radius: $border-radius;

        .mat-mdc-menu-content {
            padding: $content-padding;
        }

        .mat-mdc-menu-item {
            border-radius: $border-radius;
            height: $height;
            line-height: $height;
            padding: 0 $text-padding;
            margin-bottom: $gap;

            &:last-child {
                margin-bottom: 0;
            }

            .mat-mdc-icon {
                margin-right: $icon-padding;
            }
        }
    }
}

@mixin mat-menu-override-theme($theme) {
    $background: map-get($theme, background);

    #{$selector} {
        @include dsh-shadow($theme);

        .mat-mdc-menu-item:hover:enabled {
            background: map-get($background, hover);
        }
    }
}
