@use '@angular/material' as mat;

@mixin mat-radio-button-override() {
    .mat-mdc-radio-button * {
        cursor: pointer;
    }

    .mdc-radio {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 0 !important;
    }

    .mat-mdc-radio-label {
        white-space: normal !important;
    }
}

@mixin mat-radio-button-override-typography($config) {
    .mat-mdc-radio-button {
        font: {
            family: mat.font-family($config, body-1);
            size: mat.font-size($config, body-1);
            weight: mat.font-weight($config, body-1);
        }
    }
}
