@import '../../utils/shadow';

@mixin mat-button-override() {
    .mat-mdc-icon-button {
        &#{&}#{&}#{&} {
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
    }

    .mdc-icon-button__ripple {
        &#{&}#{&}#{&} {
            display: none;
        }
    }
}
